import { FC } from "react";

import { Alert } from "@mui/material";

type Props = {
    error: any;
};

const parseJson = (jsonString: any) => {
    try {
        const parsedObj = JSON.parse(jsonString);
        return parsedObj;
    } catch (error) {
        return null;
    }
};


const ValidationError: FC<Props> = ({ error }: Props) => {
    const getNames = (names: any) => {
        if (!names) return '';
        return names.map((name: any) => `"${name}"`).join(', ');
    };
    const getArrayLength = (array: any) => {
        if (!array) return '';
        return array.length;
    };

    const getName = (names: any, index: any) => (
        names && names.length >= index + 1 ? names[index] : ''
    );

    const getTextByErrorType = () => {
        const { type, detailsInJson } = error;
        const {
            // userNameInRow = '',
            columnName = '',
            rowNumber = '',
            sheetName = '',
            requiredSheetsNames = [],
            columnsNames = [],
            values = [],
        } = parseJson(detailsInJson) || {};
        const errorsByType: any = {
            // critical errors
            NetworkConnectionFailed: 'Network connection failed',
            SomethingWentWrong: 'Something went wrong',
            // common errors
            Common_EmptyField: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" has empty field`,
        /* To add on the backend */ Common_PermissionDenied: `Permission denied. You don’t have permission to work with "${sheetName}", please clear data at this sheet`,
            Common_SheetsNotFound: `File is not correct. File should have ${getArrayLength(requiredSheetsNames)} sheets named: ${getNames(requiredSheetsNames)}`,
            Common_ColumnsNotFound: `File format is not correct. Columns ${getNames(columnsNames)} are missing on the sheet "${sheetName}"`,
            Common_WrongColumnsOrder: `File format is not correct. Columns ${getNames(columnsNames)} are not in the right order on the sheet "${sheetName}"`,
            Common_InvalidField: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" is not valid`,
            // TODO: make the db name as a variable (the name should depend on a project)
            Common_ValueAlreadyExist: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" already exists in the DASS Database`,
            Common_ValueNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" doesn't exist in the DASS Database`,
            Common_ValueNotUniqueInFile: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" is not unique in the file`,
            Common_AssignmentPeriodOverlapsExcelPeriod: `On the "${sheetName}" sheet in the row "${rowNumber}" assignment period overlaps an existing one in the file`,
            Common_AssignmentPeriodOverlapsDbPeriod: `On the "${sheetName}" sheet in the row "${rowNumber}" assignment period overlaps an existing one in the DASS Database`,
            Common_AssignmentStartDateNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" doesn't exist in the DASS Database for this assignment. Please, specify an existing one to make changes properly`,
            Common_AssignmentStartDateGreaterThanEndDate: `On the "${sheetName}" sheet in the row "${rowNumber}" date in "${getName(columnsNames, 1)}" column comes earlier than date in "${getName(columnsNames, 0)}" column`,
            Common_GroupNotUniqueInFile: `On the "${sheetName}" sheet in the row "${rowNumber}" a group of ${getNames(columnsNames)} is not unique in the file`,
            Common_GroupAlreadyExists: `On the "${sheetName}" sheet in the row "${rowNumber}" a group of ${getNames(columnsNames)} already exists in the DASS Database`,
            Common_GroupNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" a group of ${getNames(columnsNames)} doesn't exist in the DASS Database`,
            Common_PairMismatched: `On the "${sheetName}" sheet in the row "${rowNumber}" "${getName(columnsNames, 0)}" has another "${getName(columnsNames, 1)}" in the DASS Database`,
            Common_ValuesNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" in the column "${columnName}" values ${getNames(values)} don't exist in the DASS Database`,
            Common_ValuesAlreadyExist: `On the "${sheetName}" sheet in the row "${rowNumber}" in the column "${columnName}" values ${getNames(values)} already exist in the DASS Database`,

            PolicyAlreadyExist: `On the "create" sheet in the row "${rowNumber}"  Policy "Policy_name" already exists in the DataBase`,
            PolicyNotFound: `On the "delete" sheet in the row "${rowNumber}" a group of "application_code", "policy_name" does not exist"`,
            UserAssignmentAlreadyExist: `On the "create" sheet in the row "${rowNumber}"  the group of ${getNames(columnsNames)} with policies ${getNames(values)} already exists in the DataBase`,
            UserAlreadyAssignedTeamManagerRole: `On the "${sheetName}" sheet in the row "${rowNumber}"  in the column "${columnName}" the user is already assigned to the Team Manager role`,
            UserAssignmentNotFound: `On the "delete" sheet in the row "${rowNumber}" a group of  "email", "application_code", "policy_name" does not exist`,


            // MDA errors
            MDA_ManagerNameNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" ${getNames(columnsNames)} are not contained in the file or in the DASS Database`,
        // Amazon SIA errors
        /* To rename to common in DVG db */ RowHasEmptyField: `On the "${sheetName}" sheet in the row "${rowNumber}" has empty field`,
        /* To rename to common in DVG db */ SheetsNotFound: 'File is not correct. File should have 3 sheets named "users to_create", "users_to_update", "users_to_disable"',
        /* To rename to common in DVG db */ ColumnsNotFound: `File format is not correct. Columns ${getNames(columnsNames)} are missing on the sheet "${sheetName}"`,
        /* To rename to common in DVG db */ WrongColumnsOrder: `File format is not correct. Columns ${getNames(columnsNames)} are not in the right order on the sheet "${sheetName}"`,
        /* To rename to common in DVG db */ InvalidField: `On the "${sheetName}" sheet in the row "${rowNumber}" "${columnName}" is not valid`,
            InvalidEmployeeId: `On the "${sheetName}" sheet in the row "${rowNumber}" Employee ID is not correct`,
            InvalidLocationId: `On the "${sheetName}" sheet in the row "${rowNumber}" Location ID is not correct`,
            InvalidSupervisorEmployeeId: `On the "${sheetName}" sheet in the row "${rowNumber}" Supervisor employee ID is not correct`,
            EmptyEmployeeId: `On the "${sheetName}" sheet in the row "${rowNumber}" Employee ID is blank`,
            InvalidEmail: `On the "${sheetName}" sheet in the row "${rowNumber}" email address is not valid`,
            NotFoundSupportedMarketplace: `On the "${sheetName}" sheet in the row "${rowNumber}" found unknown Supported Marketplace(s)`,
            NotFoundContactChannel: `On the "${sheetName}" sheet in the row "${rowNumber}" found unknown Contact Channel`,
            NotFoundLocationId: `On the "${sheetName}" sheet in the row "${rowNumber}" found unknown Location ID`,
            NotFoundCountryCity: `On the "${sheetName}" sheet in the row "${rowNumber}" found unknown City or Country`,
            EmptyUserName: `On the "${sheetName}" sheet in the row "${rowNumber}" username is blank`,
            EmployeeIdAlreadyExists: `On the "${sheetName}" sheet in the row "${rowNumber}" EmployeeID already exists in the Majorel Database`,
            UserNameAlreadyExists: `On the "${sheetName}" sheet in the row "${rowNumber}" username already exists in the Majorel Database`,
            UserAzureAdNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" user ${getNames(values)} does not exist in AzureAD`,
            DuplicateUsername: `On the "${sheetName}" sheet in the row "${rowNumber}" username is not unique in the file`,
            EmployeeIdNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" EmployeeID doesn’t exist in the Majorel Database`,
            UserNameNotFound: `On the "${sheetName}" sheet in the row "${rowNumber}" username doesn’t exist in the Majorel Database`,
            DifferentLocationIdOrHomeRegion: `On the "${sheetName}" sheet in the row "${rowNumber}" username exists in the Majorel Database with different location_id or home_region`,
            // TODO: to remove (on the backend too)
            MdaLob_DoesntExistInDASS: `On the "${sheetName}" sheet in the row "${rowNumber}" LOB with these LOBCode and LOBName doesn’t exist in the DASS Database`,
            MdaLob_AlreadyExistInDASS: `On the "${sheetName}" sheet in the row "${rowNumber}" LOB with these LOBCode and LOBName already exists in the DASS Database`,
            MdaLob_NotUnique: `On the "${sheetName}" sheet in the row "${rowNumber}" LOB is not unique in the file`,
            MdaEmloyeeAlreadyExistWithGuid: `On the "${sheetName}" sheet in the row "${rowNumber}" employee with this GUID already exists in the DASS Database (Guid is a combination of employee’s HrCode+CountryCode (ISO 2)+SystemCode)`,
            CollumnsNotCorrectNames: `File format is not correct. There are not correct names of the columns on the sheet "${sheetName}"`,
            UnknownColumn: `On the "${sheetName}" sheet in the row "${rowNumber}" found unknown "${columnName}"`,
            MissingColumn: `On the "${sheetName}" sheet in the row "${rowNumber}" found missing "${columnName}"`,
            Common_AssignmentDateOverlapsOtherRecord: `On the "${sheetName}" sheet in the row "${rowNumber}" Assignment period overlaps with existing one`,
            Common_AssignmentOtherRecordOverlapsDates: `On the "${sheetName}" sheet in the row "${rowNumber}" there is an open assignment. Please close previous assignment using the Master Data UI`,
        };

        return errorsByType[type] || type;
    };


    return (
        <Alert severity="error">
            {getTextByErrorType()}
        </Alert>
    );
}



export default ValidationError;
